<!--
 * @Date: 2023-11-10 14:16:08
 * @Description: 问卷列表
-->
 <template>
    <div class="questionnairelist">
        <ul>
            <li v-for="(item,index) in list" :key="index" @click="jump(item)">
                <span>{{ item.wjTitle }}</span>
                <span :class="{active:item.isFillIn}">{{ item.isFillIn?'已提交':'待提交' }}</span>
            </li>
        </ul>
        <article v-if="noData" class="ListEmptyData">
        <img src="../../../assets/images/emptyData.png" alt />
        <p>您还没有学习内容~</p>
        </article>
        <p class="loadingmore" v-if="loading">加载中...</p>
        <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
    </div>
 </template>
 <script>
import $ from "jquery";
export default{
    data(){
        return{
            noData: null,
            paging: {
                params: {
                pageNum: 1,
                pageSize: 10,
                },
                total: 0,
            },
            loading: false,
            list: [],
        }
    },
    computed: {
        noMore() {
            return (
                this.list.length === this.paging.total && this.paging.total !== 0
            );
        },
    },
    async created() {
        await this.findList();
    },
    mounted() {
        $(window).scroll(() => {
        //判断是否滑动到页面底部
        if (
            Math.round($(window).scrollTop()) ===
            $(document).height() - $(window).height()
        ) {
            // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
            // console.log('页面底部')

            setTimeout(this.reachBottom(), 1000);
        }
        });
    },
    methods:{
        reachBottom() {
            if (
                this.paging.total > this.list.length
            ) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.findList("reachBottom");
            } else {
                this.loading = false;
            }
        },
         // 列表初始化
        async findList() {
            let params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,

            };
            this.$api.learn.findQuestionnaireList(params).then((res) => {
                if (res.data) {
                    if (res.data.list && res.data.list.length) {
                        this.noData = false;
                    } else {
                        this.noData = true;
                    }
                    if (this.paging.params.pageNum > 1) {
                        this.list = this.list.concat(res.data.list);
                    } else {
                        this.list = res.data.list || [];
                    }
                    this.paging.total = res.data.total;
                }
            });
        },
        jump(item){
            this.$router.push({
                path: "/learn/questionnaire",
                query: {
                    type: 2, // type：1训练营 2培训任务
                    // typeId: this.taskId, // typeId：训练营id或者培训任务id
                    wjId: item.wjId, // wjId：问卷ID
                },
            });
        }
    }
}
</script>
 <style lang="scss" scoped>
 ul{
    margin-top: 10px;
    margin-bottom: 24px;
    li{
        padding: 0 32px;
        box-sizing: border-box;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        margin-bottom: 2px;
        border-radius: 6px;
        &:hover{
            opacity: .8;
            cursor: pointer;
            span:nth-child(1){
                color: #1A72FF;
            }
        }
        span:nth-child(1){
            font-size: 18px;
            font-weight: 500;
            color: #333;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出的内容 */
            text-overflow: ellipsis; /* 用省略号表示被隐藏的部分 */
            display: inline-block;
            width:calc(100% - 120px);
        }
        span:nth-child(2){
            font-size: 18px;
            color:#666;
            cursor: pointer;
            &.active{
                color: #1A72FF;
            }
        }
    }
 }
 </style>