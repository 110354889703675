<template>
  <div class="learnListBox">
    <!-- v-if="!tabIndex" -->
    <div class="top-content">
      <div class="contentBox">
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.todayLearnTime || 0
              )
            }}
          </p>
          <p class="label">今日累计学习(小时)</p>
        </div>
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.thisWeekLearnTime || 0
              )
            }}
          </p>
          <p class="label">本周累计学习(小时)</p>
        </div>
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.thisMonthLearnTime || 0
              )
            }}
          </p>
          <p class="label">本月累计学习(小时)</p>
        </div>
      </div>
    </div>
    <!-- <div class="titleBox">
        <img :src="tabIndex ? require('../../../assets/images/learn/exam-title.png') : require('../../../assets/images/learn/courseTitle.png')" class="titleImg" alt="">
      </div> -->
    <div class="content">
      <div class="tabBox">
        <div class="tab1">
          <p class="tabItem" :class="{tabActive:tabIndex == item.id}" @click="handleTab(item.id)"  
            v-for="(item, index) in tabs" :key="index">
            <svg-icon :iconClass="index==0?'tabActive1':'tabActive'" className="iconBg" v-if="tabIndex == item.id"></svg-icon>
            <span><svg-icon :iconClass="item.key?('tab-'+item.key):item.icon"></svg-icon>{{ item.value }}</span>
          </p>
        </div>
        <div class="tab2" v-if="[1, -1, 4].includes(tabIndex)">
          <p class="tabItem2" :class="{ tabActive: statusIndex === index }" v-for="(item, index) in courseStatusList"
            :key="item.text" @click="handleStatus(index, item)">
            {{ item.text }}
          </p>
        </div>
        <div class="tab2" v-if="[5].includes(tabIndex)">
          <p class="tabItem2" :class="{ tabActive: statusIndex === index }" v-for="(item, index) in liveStatusList"
            :key="item.text" @click="handleStatus(index, item)">
            {{ item.text }}
          </p>
        </div>
        <!-- <div class="tab2" v-if="tabIndex === 0 || tabIndex === 2">
          <p
            class="tabItem2"
            :class="{ tabActive: statusIndex === index }"
            v-for="(item, index) in courseStatusList"
            :key="item.text"
            @click="handleStatus(index, item)"
          >
            {{ item.text }}
          </p>
        </div> -->
      </div>
      <!-- v-if="tabIndex === 0" -->

      <allCoutseList v-if="tabIndex == -1" ref="courseListRef" :taskStatus="taskStatus" :tabIndex="tabIndex"
        :key="taskStatus" />

      <courseList v-if="tabIndex == 1" ref="courseListRef" :taskStatus="taskStatus" :tabIndex="tabIndex"
        :key="taskStatus" />


      <curriculumList v-if="tabIndex == 2" class="padding24" ref="curriculumRef" />
      <studyTask v-if="tabIndex == 3" class="padding24" ref="studyTaskRef" :pageNums="12" />
      <trainTaskList v-if="tabIndex == 4" ref="courseListRef" :taskStatus="taskStatus" :key="taskStatus" />
      <!-- <examList ref="examListRef" v-if="tabIndex === 1" /> -->
      <liveList
        ref="liveListRef"
        v-if="tabIndex === 5"
        :taskStatus="taskStatus"
        :key="taskStatus"
      /> 
      <questionnaire-list v-if="tabIndex==6"></questionnaire-list>

    </div>
  </div>
</template>

<script>
import courseList from "./courseList.vue";
import curriculumList from "./curriculumList.vue";
import studyTask from "./studyTask.vue";
import trainTaskList from './trainTaskList.vue';
import allCoutseList from "./allCoutseList.vue";
// import examList from "./examList.vue";
import questionnaireList from "./questionnaireList.vue";
import liveList from "./liveList";
export default {
  components: { courseList, curriculumList, studyTask, trainTaskList, allCoutseList,liveList,questionnaireList },
  data() {
    return {
      courseStatusList: [
        {
          id: 0,
          text: "全部",
        },
        {
          id: 1,
          text: "未开始",
        },
        {
          id: 2,
          text: "进行中",
        },
        {
          id: 3,
          text: "已结束",
        },
      ],
      liveStatusList: [
        {
          id: 0,
          text: "全部",
        },
        {
          id: 2,
          text: "直播中",
        },
        {
          id: 1,
          text: "未开始",
        },
        {
          id: 3,
          text: "已结束",
        },
      ],
      tabs: [],
      tabsDefault: [
        {
          value: '全部',
          id: -1,
          icon: 'tab-all'
        },
        {
          value: '项目',
          id: 1,
          icon: 'tab-project'
        },
        {
          value: '任务',
          id: 4,
          icon: 'tab-training'
        },
        {
          value: '课程',
          id: 2,
          icon: 'tab-course'
        },
        {
          value: '新员工任务',
          id: 3,
          icon: 'tab-inductionTraining'
        },
        //直播tab-live
        // {
        //   value: '问卷',
        //   id: 6,
        //   icon: 'tab-course'
        // }
      ],
      tabIndex: -1,
      statusIndex: 0,
      learnInfo: null,
      taskStatus: 0,
  

    };
  },
  watch: {
    tabIndex: {
      handler() {
        this.taskStatus = 0;
        this.statusIndex = 0;
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    const frontendConfigArr = (sessionStorage.getItem('frontendConfig') && JSON.parse(sessionStorage.getItem('frontendConfig'))) || this.$store.state.dynamic.frontendConfig || [];
    const learningTab = frontendConfigArr.length && frontendConfigArr.filter(item => {
      if (item.key === 'ss_task_tag_array_learn_center') {
        return true
      }
    })[0];

    console.log(learningTab, 'learningTab+++++++++++++++++++++++++++');
    if (learningTab && JSON.parse(learningTab.value) && JSON.parse(learningTab.value).length > 0) {
      this.tabs = (learningTab && JSON.parse(learningTab.value)) || this.tabsDefault;
    } else {
      this.tabs = this.tabsDefault;
    }
    console.log(this.tabs, 'this.tabs+++++++++++++++++++++++++++');
    this.tabIndex = +localStorage.getItem("currentTabIndex") || -1;
    this.getAllLearnedData();

  },
  methods: {
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.learnInfo = res.data || null;
      });
    },
    handleTab(index) {
      console.log(index, 'fasdfasfa');
      this.tabIndex = index;
      localStorage.setItem("currentTabIndex", this.tabIndex);
    },
    handleStatus(index, item) {
      this.statusIndex = index;
      this.taskStatus = item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.textSelect{
   color: #fff;
}
.tabActiveTabAll{
  width: 160px;
  height: 67px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/selectTabAll.png') no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabActiveTabOne{
  width: 160px;
  height: 67px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/selectTabOne.png') no-repeat;
  display: flex;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabImg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
</style>
<style lang="scss" src="../asset/css/index.scss" scoped></style>
