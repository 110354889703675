<template>
  <div class="courseList">
    <div class="item" v-for="item in courseList" :key="item.id" @click="goDetail(item,1)">
      <img src="../../../assets/images/learn/new-icon.png" v-if="item.isNew" class="new-icon" alt="" />
      <div class="item-left">
        <img :src="item.coverImage" class="cover" alt="" />
        <wd-tag :item="item"></wd-tag>
      </div>
      <div class="info">
        <p class="name">
          <span>{{ item.name }}</span>
        </p>
        <!-- <p class="time">有效期：{{ item.time }}</p> -->
        <p class="hourCredit">
          <template v-if="item.credit">
            <svg-icon icon-class="xiaolian"></svg-icon>
            <span>学分：<span>{{ item.credit }}</span></span>
          </template>
        </p>
        <p class="label" v-if="item.courseItemTypeList">
          <!-- 1: 目录 2:课程 -->
          <span class="labelItem" v-for="(labelItem, index) in item.courseItemTypeList" v-show="labelItem.type != 1"
            :key="index + 'label'">
            <span>
              {{ ConstData.taskType[labelItem.type].text }}
              ：{{ labelItem.count }}
            </span>
          </span>
        </p>
        <div class="progress">
          <el-progress color="$WDPrimaryColor" define-back-color="#E9E9E9" text-color="$WDPrimaryColor"
            :percentage="item.studyProcess"></el-progress>
          <span class="hour">已学习{{ formateTime(item.learnTotalTime) }}h</span>
        </div>
      </div>
      <div class="botton-end" v-if="$dateFormat.dateFormat() > item.endTime &&
      (item.studyType === 2 || item.studyType === 1)
      ">
        <p class="btn">已结束</p>
      </div>
      <div class="botton" v-else>
        <p class="btn" @click.stop="goDetail(item)">
          {{ item.isNew ? "开始学习" : "继续学习" }}
        </p>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>您还没有学习内容~</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    taskStatus: {
      type: Number,
      default: 0,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      noData: null,
      paging: {
        params: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
      },
      loading: false,
      courseList: [],
    };
  },
  computed: {
    noMore() {
      return (
        this.courseList.length === this.paging.total && this.paging.total !== 0
      );
    },
  },
  async created() {
    await this.findTaskList();
  },
  mounted() {
    $(window).scroll(() => {
      //判断是否滑动到页面底部
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
        // console.log('页面底部')

        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    goDetail(item,type) {
      if (
        this.$dateFormat.dateFormat() < item.startTime &&
        (+item.studyType === 1 || +item.studyType === 2)
      ) {
        this.$message.warning("课程未开始");
      } else if (item.isExpired) {
        this.$message.warning("已到期，无法学习");
      } else {
        /**
         * displayStyle 1 列表样式，2 地图样式
         */
        if (item.rules.displayStyle === 1 ) {
          if (type || item.isNew) {
            this.toJump(item)
          } else {
            const params = {
              taskId: item.id,
            };
            this.$api.learn.quickLearn({params}).then((res) => {
              if (res.data) {
                if(res.data.lastPlayItemId){
                  this.$router.push({
                    path: "/course/play",
                      query: {
                        taskId: item.id,
                        resId: res.data.lastPlayItemId,
                        studyProcess: item.playLength,
                      },
                  });
                }else{
                  this.toJump(item);
                }
              }
            });
          }
        } else {
          this.$router.push({
            path: "/learn/StageMap",
            query: {
              id: item.id,
            },
          });
        }
      }
    },
    toJump(item){
      this.$router.push({
        path: "/learn/course/detail",
        query: {
          id: item.id,
          type:item.type
        },
      });
    },
    formateTime(val) {
      if (val) {
        if ((val / 3600).toFixed(2) > 0) {
          return (val / 3600).toFixed(2);
        } else {
          return (val / 3600).toFixed(0);
        }
      } else {
        return val;
      }
    },
    reachBottom() {
      console.log(111111111,this.paging.total > this.courseList.length);
      if (
        this.paging.total > this.courseList.length
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.findTaskList("reachBottom");
      } else {
        this.loading = false;
      }
    },
    // 列表初始化
    async findTaskList() {
      let params = {
        pageNum: this.paging.params.pageNum,
        pageSize: this.paging.params.pageSize,
        taskStatus: this.taskStatus || "",
        type:-1

      };

      await this.$api.learn.findTaskList({ params }).then(async (res) => {
        if (res.data) {
          let ids = [];
          if (res.data.list && res.data.list.length) {
            this.noData = false;
            res.data.list.forEach((item) => {
              ids.push(item.id);
            });
          } else {
            this.noData = true;
          }
          if (this.paging.params.pageNum > 1) {
            this.courseList = this.courseList.concat(res.data.list);
          } else {
            this.courseList = res.data.list || [];
          }
          await this.findTaskLearningInfo(ids.join(","));
          this.paging.total = res.data.total;
        }
      });
    },
    // 二次渲染 学习相关信息
    async findTaskLearningInfo(ids) {
      let params = {
        param: ids,
      };
      await this.$api.learn.findTaskLearningInfo({ params }).then((res) => {
        if (res.data) {
          this.courseList.forEach((item) => {
            for (let key in res.data) {
              if (Number(key) === item.id) {
                item.contentTypeSet = res.data[key].contentTypeSet;
                item.studyProcess = res.data[key].studyProcess;
                // item.courseItemTypeList = res.data[key].courseItemTypeList.filter((item) => { return item.type !== 2 });
                item.courseItemTypeList = res.data[key].courseItemTypeList;
                item.courseNum = res.data[key].courseNum;
                item.learnTotalTime = res.data[key].learnTotalTime;
                // console.log(item.courseItemTypeList, 'item.courseItemTypeList')
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" src="../asset/css/list.scss" scoped></style>
<style lang="scss" scoped>
.courseList .item{
  cursor: pointer;
}
</style>